import { useQuery } from '@apollo/client';
import { GetSupplies, GetSupplies_getSupplies } from 'graphql/GetSupplies';
import { GET_SUPPLIES } from './get-supplies.query';

interface SupplySearch {
    loading: boolean;
    supplies: readonly GetSupplies_getSupplies[];
}

export function useSuppliesList(): SupplySearch {
    
    const { data: supplies, loading } = useQuery<GetSupplies>(GET_SUPPLIES);

    return {
        supplies:  supplies?.getSupplies || [],
        loading
    };
}