import React from 'react';
import { useClientAutocomplete } from '../../use-client-autocomplete';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { AffectedResource, createNotification } from 'store/notifications/actions';
import { ADD_SUPPLY_CONSUMPTION } from './add-mileage-recording.mutation';
import { ConsumeSuppliesForm, ConsumeSuppliesRequest } from 'forms/supervisor/consume-supplies';
import { useSuppliesList } from 'components/hooks/search/supplies/supplies-list';
import { AddConsumedSupplyEvent, AddConsumedSupplyEventVariables } from 'graphql/AddConsumedSupplyEvent';


export function AddSupplyConsumption(props: {
    onClose: () => void
}) {


    const { loadingClientOptions, companies, handleClientAutocomplete } = useClientAutocomplete();
    const [addSupplyConsumption, { loading: processingSave }] = useMutation<AddConsumedSupplyEvent, AddConsumedSupplyEventVariables>(ADD_SUPPLY_CONSUMPTION);
    const dispatch = useDispatch();
    const { supplies } = useSuppliesList();

    const handleSubmit = (request: ConsumeSuppliesRequest) => {

        return addSupplyConsumption({
            variables: {
                request: {
                    companyId: request.clientId || '',
                    consumedSupplies: request.supplies.map(x => ({
                        quantity: x.quantity,
                        supplyId: x.supplyId
                    })),
                    purchaseDate: request.purchaseDate
                }
            }
        }).then(response => {
            if (response.data && !response.errors) {
                dispatch(createNotification({
                    subject: 'Supply Consumption Saved',
                    id: response.data.addConsumedSupplyEvent.id,
                    resource: AffectedResource.ConsumedSupply,
                }));
                props.onClose();
            }
        });
    };


    return (
        <div>
            <ConsumeSuppliesForm
                supplyOptions={supplies.map(x => ({ value: x.id, label: x.name }))}
                loading={processingSave}
                mode={"create"}
                onSubmit={handleSubmit}
                formLabels={{}}
                clientOptions={companies?.findCompanies.map(val => ({ label: val.companyName, value: val.id })) || []}
                onClientAutocompleteChange={handleClientAutocomplete}
                loadingClientOptions={loadingClientOptions}
            />
        </div>
    );
}