import React from 'react';
import styled, { ColorOptions } from 'styled-components';
import 'styled-components';
import Select from 'react-select';


export const FormGroup = styled.div` 
  margin-bottom:20px; 
  margin-top:25px;
  margin-right: 25px;
`;


export const SelectFormGroup = styled.div` 
  margin-bottom: 5px; 
  margin-top: 5px;
  margin-right: 25px;
`;

export const ErrorMessage = styled.div<{ showFullError: boolean, size?: 'md' | 'lg' }>`
color: ${props => props.theme.colors.error.self};
font-size: ${({ size }) => size === 'lg' ? '24px' : '16px'};
font-weight: 700;
${props => !props.showFullError &&
    'overflow: hidden;  white-space: nowrap; text-overflow: ellipsis;'}
width: 100%;
overflow-wrap:ellipsis;
`;

export const FormGrouping = (props: { error?: any, children: React.ReactNode }) => {

  const { error } = props;
  const [showError, setShowError] = React.useState(false);
  const showFullText = () => setShowError(!showError);
  return (
    <FormGroup>
      {props.children}
      <ErrorMessage showFullError={showError} onClick={showFullText}>{error}</ErrorMessage>
    </FormGroup>
  )
}

export const SelectFormGrouping = (props: { error?: any, children: React.ReactNode }) => {

  const { error } = props;
  const [showError, setShowError] = React.useState(false);
  const showFullText = () => setShowError(!showError);
  return (
    <SelectFormGroup>
      {props.children}
      <ErrorMessage showFullError={showError} onClick={showFullText}>{error}</ErrorMessage>
    </SelectFormGroup>
  )
}



export const FormLabel = styled.label`
  color: ${props => props.theme.layout.text};
  font-size:14px;
  font-weight:normal;
  position:relative;
  pointer-events:none;
  left:5px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
`;


export const SelectLabel = styled.label`
  color: ${props => props.theme.layout.text};
  font-size:14px;
  font-weight:normal;
  position:relative;
  pointer-events:none;
  left:5px; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
`;

export const Input = styled.input<{ color?: ColorOptions }>`  
color: ${props => props.color ? props.theme.colors[props.color].text : props.theme.layout.text};
padding:4px 4px;
width:100%;
height:30px;
background-color: transparent;
border:none;
border-bottom:1px solid ${props => props.color ? props.theme.colors[props.color].text : props.theme.layout.text};
&:focus {
   outline:none;
   border-bottom:2px solid ${props => props.theme.colors.primary.self}; 
}

&:focus ~ ${FormLabel} , &:not(:placeholder-shown) ~ ${FormLabel}   {
  top:-50px;
  font-size:14px;
  color:${props => props.theme.colors.primary.self};
}
&:disabled {    
  font-style: italic;
  border-bottom: 2px dotted;
  cursor: not-allowed;
}
& ~ ${FormLabel}{

  top:-25px;
}
`;

export const TextArea = styled.textarea`
  color: ${props => props.theme.layout.text};
  padding:4px 4px;
  display:block;
  width:100%;
  background-color: transparent; 
  border:1px solid ${props => props.theme.layout.text};
  &:focus {
     outline:none;
     border:2px solid ${props => props.theme.colors.primary.self}; 
  }
  
  &:focus ~ ${FormLabel} , &:not(:placeholder-shown) ~ ${FormLabel}   { 
    font-size:14px;
    color:${props => props.theme.colors.primary.self};
  }
  &:disabled {    
    font-style: italic;
  }
`;


export const ReactSelect = styled(Select)`
  color: ${props => props.theme.layout.text};
  padding:4px 4px;
  display:block;
  width:100%;
  background-color: transparent; 
  border:1px solid ${props => props.theme.layout.text};
  &:focus {
    outline:none;
    border:2px solid ${props => props.theme.colors.primary.self}; 
  }

  &:focus ~ ${FormLabel} , &:not(:placeholder-shown) ~ ${FormLabel}   {
    top:-18px;
    font-size:14px;
    color:${props => props.theme.colors.primary.self};
  }

`;

export const Option = styled.option`

`;