import {
  NotificationState,
  CREATE_NOTIFICATION,
  EXPIRE_NOTIFICATION,
  ACKNOWLEDGE_NOTIFICATION,
  NotificationActionTypes,
} from "./actions";
import { LOGIN, LOG_OUT } from "store/account/actions";

const initialState: NotificationState = {
  notifications: [],
};

export default function notificationsReducer(
  state = initialState,
  action: NotificationActionTypes
): NotificationState {
  switch (action.type) {
    case LOGIN: {
      const payload = action.payload;
      return {
        notifications: [
          ...state.notifications,
          {
            subject: `Welcome ${payload.firstname}!`,
            id: ((Math.random() * 100000) | 0) + "",
            timestamp: new Date(),
          },
        ],
      };
    }
    case LOG_OUT: {
      return {
        notifications: [
          ...state.notifications,
          {
            subject: `You have been successfully logged out.`,
            id: ((Math.random() * 100000) | 0) + "",
            timestamp: new Date(),
          },
        ],
      };
    }
    case CREATE_NOTIFICATION: {
      const payload = action.payload;
      return {
        notifications: [
          ...state.notifications,
          {
            ...payload,
            id: payload.id || ((Math.random() * 100000) | 0) + "",
            timestamp: new Date(),
          },
        ],
        affectedResource: payload.resource,
      };
    }
    case ACKNOWLEDGE_NOTIFICATION:
    case EXPIRE_NOTIFICATION: {
      return {
        notifications: state.notifications.filter(
          (notif) => notif.id !== action.payload.notificationId
        ),
      };
    }
    default: {
      return state;
    }
  }
}
