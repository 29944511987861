import gql from 'graphql-tag';

export const ADD_SUPPLY_CONSUMPTION = gql`
mutation AddConsumedSupplyEvent($request: AddConsumedSupplyInput!){
  addConsumedSupplyEvent(request: $request) {
    id
    createdOn
    type 
  }
}
`;