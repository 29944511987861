import React from 'react';
import styled, { StyledComponent, DefaultTheme, ColorOptions, ColorType } from "styled-components"; ;

export default function WithColor<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>, T extends Object>(Item: StyledComponent<C, DefaultTheme, T, never>, props: {color: ColorOptions, colorType?: ColorType}) {
    const {color, colorType} = props;
    const A= styled(Item)`
        color: ${props => props.theme.colors[color][colorType || "self"]};
    `;
    return A;
}
