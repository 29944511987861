import { useLazyQuery } from '@apollo/client';
import { FIND_COMPANY_AUTOCOMPLETE } from './find-company-autocomplete.query';
import { findCompanyAutocomplete, findCompanyAutocompleteVariables } from 'graphql/findCompanyAutocomplete';

export function useClientAutocomplete() {
    
    const [getCompanies, {loading:loadingClientOptions, data: companies }] = useLazyQuery<findCompanyAutocomplete, findCompanyAutocompleteVariables>(FIND_COMPANY_AUTOCOMPLETE);

    if(!loadingClientOptions && !companies) {
        getCompanies({
            variables: {
                companyName: '',
            }
        });
    }
    const handleClientAutocomplete = (val: string) => {
        getCompanies({
            variables: {
                companyName: val
            }
        });
    };

    return {
        loadingClientOptions,
        companies,
        handleClientAutocomplete
    }
}