import { AppState } from "store";
import { Permission } from "graphql/globalTypes";

const isLoggedIn = (state: AppState) => !!state.account.accountId;
const accountId = (state: AppState) => state.account.accountId;
const hasPermission = (authCode: Permission) => (state: AppState) =>
  state.account.permissions?.includes(authCode);
const fullName = (state: AppState) =>
  state.account.firstname + " " + state.account.lastname;
export const AccountSelectors = {
  isLoggedIn,
  accountId,
  hasPermission,
  fullName,
};
