import { themes } from "themes";
import { ThemeState, ThemeActionTypes } from "./actions";

const initialState: ThemeState = {
    theme: themes[0].theme,
    name: themes[0].name,
    index: 0,
};

export default function themeReducer(state = initialState, action: ThemeActionTypes): ThemeState {
    switch(action.type) {
        case "CHANGE_THEME": {
            const index = (state.index + 1) % themes.length;
            const theme = themes[index];
            return {
                theme: theme.theme,
                name: theme.name,
                index: index,
            };
        }
        case "RESET_THEME": {
            const index = 0;
            const theme = themes[index];
            return {
                theme: theme.theme,
                name: theme.name,
                index: index,
            };
        }
        default: {
            return state;
        }            
    }
}