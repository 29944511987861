import React from 'react';
import Header from 'components/layout/header';
import Navbar from 'components/navigation/navbar';
import useNavbar from 'components/hooks/nav-bar-hook';
import { Permission } from 'graphql/globalTypes';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Page } from 'pages/page.interface';
import WithColor from 'components/utils/colored-icon';
import styled, { StyledComponent, DefaultTheme } from 'styled-components';
import { ButtonLink } from 'components/button-link';
import { usePermission } from 'components/hooks/use-permission';
export default function HomePage(props: Page) {

    const { isOpen, openNav, closeNav } = useNavbar();

    const [canManageAccounts] = usePermission(Permission.MANAGE_ACCOUNTS);
    const [canViewCompanies] = usePermission(Permission.VIEW_COMPANY);
    const [canEditSupplies] = usePermission(Permission.EDIT_SUPPLIES);
    const [canViewSupervisorPlanner] = usePermission(Permission.SUPERVISOR_PLANNER);
    const [canViewScheduling] = usePermission(Permission.SCHEDULING);

    const buttons: [boolean, { label: string, link: string, view: StyledComponent<StyledComponent<"div", DefaultTheme, {}, never>, DefaultTheme, {}, never> }][] = [
        [canViewCompanies, { label: 'Clients', link: '/clients/search', view: PrimaryCenteredTextBox }],
        [canViewSupervisorPlanner, { label: 'Supervisors', link: '/supervisor', view: PrimaryCenteredTextBox }],
        [canManageAccounts, { label: 'Users', link: '/accounts/search', view: PrimaryCenteredTextBox }],
        [canEditSupplies, { label: 'Configuration', link: '/configuration', view: PrimaryCenteredTextBox }],
        [canManageAccounts, { label: 'Live Feed', link: '/livefeed', view: PrimaryCenteredTextBox }],
        [canManageAccounts, { label: 'Employees', link: '/employees', view: PrimaryCenteredTextBox }],
        [canViewScheduling, { label: 'Scheduling', link: '/scheduling', view: PrimaryCenteredTextBox }],
    ];
    const viewableButtons = buttons.filter(button => button[0]).map(x => x[1]);
    return (
        <React.Fragment>
            <Header
                titleClicked={() => { }}
                title={'Neat \'N Clean'}
                menuPosition={'right'}
                menuClicked={openNav}
            />
            <Navbar navPosition='right' isOpen={isOpen} handleClosure={closeNav}>
            </Navbar>
            <Grid>
                <Row around={'sm'}>
                    {
                        viewableButtons.map(button =>

                            <Col key={button.label} xs={12} sm={6} >
                                <ButtonLink to={button.link}>
                                    <button.view >
                                        <h3>{button.label}</h3>
                                    </button.view>
                                </ButtonLink>
                            </Col>
                        )
                    }

                </Row>

            </Grid>
        </React.Fragment>
    )
}


export const Paper = styled.div`
  border-radius: 5px;
  border: 1px solid;
  padding: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.19),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
    
`;
export const ClickableBox = styled(Paper)`
border-radius: 5px;
cursor: pointer;
margin-top: 10px;
  :hover {
    transform: scale(1.01);
}
width: 100%;
`;

export const CenteredTextBox = styled(ClickableBox)`
text-align: center;
`;

export const PrimaryCenteredTextBox = WithColor(CenteredTextBox, {
    color: 'primary',
    colorType: 'self'
});

export const SecondaryCenteredTextBox = WithColor(CenteredTextBox, {
    color: 'secondary',
    colorType: 'self'
});

export const TertiaryCenteredTextBox = WithColor(CenteredTextBox, {
    color: 'tertiary',
    colorType: 'self'
});