export interface EnvironmentConfig {
    readonly baseUri: string;
    readonly graphqlUri: string;
    readonly environmentName: string
    readonly pollInterval: number;
  }
  
  
  const getDevEnv = (): EnvironmentConfig => ({
    baseUri: 'http://localhost:4000',
    graphqlUri: 'http://localhost:4000/graphql',
    environmentName: 'development',
    pollInterval: 1000,
  })
  
  const getStagingEnv = (): EnvironmentConfig => ({
    baseUri: 'https://nnc-staging.herokuapp.com/',
    graphqlUri: 'https://nnc-staging.herokuapp.com/graphql',
    environmentName: 'staging',
    pollInterval: 30 * 1000,
  });
  const getProductionEnv = (): EnvironmentConfig => ({
    baseUri: 'https://nnc-production.herokuapp.com/',
    graphqlUri: 'https://nnc-production.herokuapp.com/graphql',
    environmentName: 'production',
    pollInterval: 30 * 1000,
  });
  
  export const getEnvironmentConfig = (() => {
  
    let env: EnvironmentConfig;
  
    const f = () => {
      
      console.log('determining env: ' +JSON.stringify(process.env.REACT_APP_ENV))
      if (env) {
        return env;
      }
      const envName= process.env.REACT_APP_ENV || process.env.STORYBOOK_APP_ENV;
      if (envName) {
        const namedEnv: string = envName.trim();
        switch (namedEnv) {
          case "development":
            env = getDevEnv();
            break;
          case "staging":
            env = getStagingEnv();
            break;
          case "production":
            env = getProductionEnv();
            break;
          case 'ci': 
            env = getDevEnv();
            break;
          default:
            throw new Error("Environment is not configured");
        }
        return env;
      }
      else {
        throw new Error('REACT_APP_ENV is not defined');
      }
    }
    return f;
  })();
  