import React from 'react';
import { FormProps } from 'forms/form-props';
import * as Yup from 'yup';
import { SelectOptions } from 'components/autocomplete/shared';
import { Formik, Form } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Autocomplete from 'components/autocomplete/autocomplete';
import { FormGrouping, TextArea, FormLabel } from 'components/form/input';
import Button from 'components/button';

export interface AddNoteRequest {
    note: string;
    clientId?: string;
    needsEscalation: boolean;
}

export function AddNoteForm(props: FormProps<AddNoteRequest> & {
    onClientAutocompleteChange: (search: string) => void,
    clientOptions: SelectOptions,
    loadingClientOptions: boolean,
}) {
    const { onSubmit, loading, disabled } = props;
    const schema = Yup.object<AddNoteRequest>().shape({
        note: Yup.string().default('').required('Note is required'),
        clientId: Yup.string().default('').required('Client is required'),
        needsEscalation: Yup.boolean().default(false).required(),
    }).required();


    return (
        <Formik
            initialValues={schema.default()}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit({
                    ...values
                });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.clientId && errors.clientId}>
                                    <Autocomplete
                                        name={'clientId'}
                                        label={'Client'}
                                        onSelect={(val) => setFieldValue('clientId', val)}
                                        value={values.clientId}
                                        options={props.clientOptions}
                                        disabled={disabled}
                                        loading={props.loadingClientOptions}
                                        onSearchUpdate={props.onClientAutocompleteChange}
                                    />
                                </FormGrouping>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.note && errors.note}>
                                    <TextArea id={"note"} name={"note"} rows={4} placeholder={" "} value={values.note} onChange={handleChange} onBlur={handleBlur} />
                                    <FormLabel htmlFor={"note"}>Note</FormLabel>
                                </FormGrouping>
                            </Col>
                        </Row>
                        {props.serverError && <Row>
                            <Col xs={12}>{props.serverError}</Col>
                        </Row>}
                        <Row>
                            <Col>
                                <Button type={"submit"} mode={"primary"} disabled={loading}>Save Note</Button>
                            </Col>
                        </Row>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}