import React from 'react';
import { AddReminderForm, AddReminderRequest } from 'forms/supervisor/add-reminder';
import { useClientAutocomplete } from '../../use-client-autocomplete';
import { AddSupervisorReminder, AddSupervisorReminderVariables } from 'graphql/AddSupervisorReminder';
import { useMutation } from '@apollo/client';
import { ADD_SUPERVISOR_REMINDER_MUTATION } from './add-reminder.mutation';
import { useDispatch } from 'react-redux';
import { AffectedResource, createNotification } from 'store/notifications/actions';


export function AddReminder(props: {
    onClose: () => void
}) {

    const { loadingClientOptions, companies, handleClientAutocomplete } = useClientAutocomplete();
    const [addReminder, { loading: processingSave }] = useMutation<AddSupervisorReminder, AddSupervisorReminderVariables>(ADD_SUPERVISOR_REMINDER_MUTATION);
    const dispatch = useDispatch();

    const handleSubmit = (request: AddReminderRequest) => {
        return addReminder({
            variables: {
                input: {
                    companyId: request.clientId || '',
                    dueDate: request.dueDate,
                    reminder: request.reminder
                }
            },
        }).then(response => {
            if (response.data && !response.errors) {
                const reminder = response.data.addSupervisorReminder;
                dispatch(createNotification({
                    subject: 'Reminder Saved',
                    id: reminder.id,
                    link: `/supervisor/reminder/${reminder.id}`,
                    resource: AffectedResource.Reminder,
                }));
                props.onClose();
            }
        })
    };

    return (
        <div>
            <AddReminderForm
                loading={processingSave}
                mode={"create"}
                onSubmit={handleSubmit}
                formLabels={{}}
                clientOptions={companies?.findCompanies.map(val => ({ label: val.companyName, value: val.id })) || []}
                onClientAutocompleteChange={handleClientAutocomplete}
                loadingClientOptions={loadingClientOptions}
            />
        </div>
    );
}