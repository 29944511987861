import React from "react";


export default function useNavbar() {
    
  const [isOpen, setIsOpen] = React.useState(false);
  const openNav = () => setIsOpen(true);
  const closeNav = () => setIsOpen(false);

  return {isOpen, openNav, closeNav};
}