import React from 'react';
import * as StyledReactModal from "styled-react-modal";
import styled from 'styled-components';
import Button from './button';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Modal = styled(StyledReactModal.default)`
  min-width: 40rem;
  max-width:90vw;
  min-height: 20rem;
  max-height:90vh;
  display: flex;
  align-items: center;
  justify-content: center; 
`;


const ModalContainer = styled.div`
  background-color: ${props => props.theme.layout.background};
  padding-bottom: 5px;
  padding-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;  
  width: 600px; 
  max-width: 98vw;
  min-width: 25vw;
`;

const ModalHeader = styled.div`
  padding: 5px;
  padding-left:10px;
  background-color: ${props => props.theme.colors.primary.self};
  color: ${props => props.theme.colors.primary.text};
  min-height:10px;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
`

export function useModal() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  const [ModalView, setModalView] = React.useState<JSX.Element | undefined>(undefined);
  const [modalTitle, setModalTitle] = React.useState('');

  const handleOpenModal = (view: JSX.Element, title: string) => {
    setModalView(view);
    setModalTitle(title);
    openModal();
  };

  return {
    isModalOpen,
    closeModal,
    openModal,
    ModalView,
    modalTitle,
    handleOpenModal
  };

}

export const ModalViewer = (props: {
  title: string,
  children?: JSX.Element,
  closeModal: () => void,
  isModalOpen: boolean,
}) => {
  const { title, children, closeModal, isModalOpen } = props;
  return (<Modal isOpen={isModalOpen} onEscapeKeydown={closeModal}>
    <ModalContainer>
      <ModalHeader>
        <Grid fluid>
          <Row between={"xs"} middle='xs'>
            <Col xs={10}>
              <h3>{title}</h3>
            </Col>
            <Col xs={2}>
              <Button mode={"secondary"} tight onClick={closeModal}>Close</Button>
            </Col>
          </Row>
        </Grid>
      </ModalHeader>
      <div style={{ paddingTop: '15px' }}>
        {children && children}
      </div>
    </ModalContainer>
  </Modal>);
}