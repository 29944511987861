import gql from 'graphql-tag';

export const GET_SUPPLIES = gql`
query GetSupplies {
  getSupplies {
    id
    name
    monetaryValue {
      localized
      amountInCents
    }
  }
}
`;