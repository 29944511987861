import { EnvironmentConfig } from "./environment";
import { onError } from "@apollo/client/link/error";
import { navigate } from "@reach/router";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client/core";
import { storeType } from "App";
import {
  AffectedResource,
  createNotification,
} from "store/notifications/actions";

const errorLink = (store: any) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors?.some((x) => x.extensions?.exception?.status === 401)) {
      // unauthorized
      clearToken();
      !window.location.pathname.includes("/account/login") &&
        navigate(`/account/login/?redirect=${window.location.pathname}`);
      store.dispatch(
        createNotification({
          subject: "Session expired. Please log in again.",
          details: "",
          id: "credentials_invalid",
          level: "error",
          resource: AffectedResource.Account,
        })
      );
    } else if(graphQLErrors) {
      store.dispatch(
        createNotification({
          subject: 'A server error occurred',
          details: graphQLErrors[0].message,
          level: 'error',
          id: 'server error'
        })
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // @apollo/client/link/retry
    }
  });

export const getApolloClient = (
  envConfig: EnvironmentConfig,
  store: storeType
) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      errorLink(store),
      createHttpLink({
        uri: envConfig.graphqlUri,
        fetch: (uri: RequestInfo, options: RequestInit | undefined) => {
          if (options?.headers) {
            (options.headers as any).Authorization = getAuthToken();
          }
          return fetch(uri, options);
        },
      }),
    ]),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
    },
    assumeImmutableResults: true,
  });
export function getAuthToken(): string {
  const token = localStorage.getItem("token");
  return token ? `jwt ${token}` : "";
}

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
}

export function isAuthenticated(): boolean {
  return !!localStorage.getItem("token");
}

export function clearToken() {
  localStorage.removeItem("token");
}
