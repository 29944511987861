import React, { lazy } from 'react';
import { Router, Redirect, NavigateOptions, useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { AccountSelectors } from 'store/account/selectors';
import { Page } from './page.interface';
import HomePage from './home';

import { NoteAdd } from '@styled-icons/material/NoteAdd';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { QuickLinks } from 'components/quick-links';
import { Permission } from 'graphql/globalTypes';
import { useModal, ModalViewer } from 'components/modal';
import { AddReminder } from './supervisor/components/add-reminder';
import { AddNotes } from './supervisor/components/add-notes';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { AddSupplyConsumption } from './supervisor/components/consume-supplies';
import { PersonLinesFill } from '@styled-icons/bootstrap/PersonLinesFill';
export const Loading = () => <div>Loading...</div>;

export function useLazyNavigate(to: string, options?: NavigateOptions<{}>) {
  const navigate = useNavigate();
  return () => { setTimeout(() => navigate(to, options), 100) };
}
const LazyAccountsHomePage = lazy(() => import('./users'));
const LazyClientHome = lazy(() => import('./client'));
const LazyConfiguration = lazy(() => import('./configuration'));

const LazySupervisor = lazy(() => import('./supervisor'));

const LazyLiveFeed = lazy(() => import('./livefeed'));

const LazyEmployeePage = lazy(() => import('./employee'))
const LazyScheduling = lazy(() => import('./scheduling'))

const HideSupervisorLink = (props: Page) => <></>

const SupervisorQuickLinks = (props: Page) => {

  const navigate = useNavigate();
  const canHaveSupervisorPlanner = useSelector(AccountSelectors.hasPermission(Permission.SUPERVISOR_PLANNER));
  const { isModalOpen, closeModal, handleOpenModal, ModalView, modalTitle } = useModal();

  const actions: {
    icon: JSX.Element;
    name: string;
    action: () => void;
  }[]
    = [
      { icon: <NoteAdd size={30} />, name: "Note", action: () => handleOpenModal(<AddNotes onClose={closeModal} />, 'Add Note') },
      { icon: <Calendar size={30} />, name: "Reminder", action: () => handleOpenModal(<AddReminder onClose={closeModal} />, 'Add Reminder') },
      { icon: <PackageIcon size={30} />, name: "Supplies", action: () => handleOpenModal(<AddSupplyConsumption onClose={closeModal} />, 'Add Supply Consumption') },
      {
        icon: <PersonLinesFill size={30} />, name: "Add Stop", action: () => navigate('/supervisor/submit-planner')
      },
    ].filter(y => !!y) as {
      icon: JSX.Element;
      name: string;
      action: () => void;
    }[];
  if (canHaveSupervisorPlanner) {
    return (<>
      <QuickLinks actions={actions} hidden={isModalOpen} />
      <ModalViewer closeModal={closeModal} isModalOpen={isModalOpen} title={modalTitle}>
        {ModalView || undefined}
      </ModalViewer>
    </>);
  }
  return null;
}

export default function MainPage(props: Page) {


  const isLoggedIn = useSelector(AccountSelectors.isLoggedIn);
  const redirect = useLazyNavigate('/account/login');

  const { isModalOpen, closeModal, modalTitle, ModalView } = useModal();


  setTimeout(() => {
    if (!window.location.pathname.includes('/account/login') && !isLoggedIn) {
      redirect();
    }

  }, 300);

  return (
    <React.Fragment>
      <Router>
        <Redirect noThrow from={"/"} to={"/home"} />
        <LazyClientHome pathLabel={'Clients'} path={'/clients/*'} />
        <HomePage path={'/home/*'} pathLabel={'Home'} />
        <LazyAccountsHomePage path={'/accounts/*'} pathLabel={'Accounts'} />
        <LazyConfiguration path={'/configuration/*'} />
        <LazySupervisor path={'/supervisor/*'} />
        <LazyLiveFeed path={'/livefeed/*'} />
        <LazyEmployeePage path={'/employees/*'} />
        <LazyScheduling path={'/scheduling/*'} />
      </Router>
      <Router primary={false}>
        <HideSupervisorLink path={'supervisor/*'} />
        <SupervisorQuickLinks path={'/*'} />
      </Router>
      <ModalViewer
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        title={modalTitle}
      >
        {ModalView || undefined}
      </ModalViewer>
    </React.Fragment >
  )
}