import { AccountSelectors } from 'store/account/selectors';
import { useSelector } from 'react-redux';
import { Permission } from 'graphql/globalTypes';
import { useLazyNavigate } from 'pages';

export function usePermission(authCode: Permission, options?: {
    reRouteIfMissing: boolean,
}): [boolean, {isLoggedIn: boolean}] { 

    const isLoggedIn = useSelector(AccountSelectors.isLoggedIn)
    const hasPermission = useSelector(AccountSelectors.hasPermission(authCode));
    const navigate = useLazyNavigate('/access-denied', {
        replace: true
    });
    if(!isLoggedIn || (options?.reRouteIfMissing && !hasPermission)) {
        navigate();
    }

    return [hasPermission, {isLoggedIn}];

}