import styled, { StyledComponent, DefaultTheme } from 'styled-components';


export function Clickable<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>, T extends Object>(Item: StyledComponent<C, DefaultTheme, T, never>) {
    return styled(Item)<T>`
        cursor: pointer !important;
    `;
}

export default Clickable;
