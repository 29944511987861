export const SET_SCHEDULING_REGION = "SET_SCHEDULING_REGION";

export interface SchedulingState {
  selectedRegionId?: string;
}

export interface SetRegionPayload {
  regionId?: string;
}

export interface SetRegionAction {
  type: typeof SET_SCHEDULING_REGION;
  payload: SetRegionPayload;
}

export function setRegion(payload: SetRegionPayload): SetRegionAction {
  return { type: SET_SCHEDULING_REGION, payload };
}

export type SchedulingActionTypes =
  | SetRegionAction;
