import { AppState } from "store";
import { AffectedResource } from "./actions";

const getNotifications = (state: AppState) => state.notifications.notifications;
const getNotificationsForResource = (resource: AffectedResource) => (
  state: AppState
) =>
  state.notifications.affectedResource === resource
    ? state.notifications.affectedResource
    : undefined;

export const NotificationSelectors = {
  notifications: getNotifications,
  notificationsByResource: getNotificationsForResource,
};
