import gql from 'graphql-tag';

export const ADD_SUPERVISOR_NOTE =gql`
mutation AddSupervisorNote($input: AddSupervisorNoteInput!) {
  addSupervisorNote(request: $input) {
    id
    createdOn
    note
    requiresEscalation
    userEvent {
      id
      account {
        firstName
        id
      }
      payload {
        __typename
        ... on SupervisorNote {
          id
          note
          requiresEscalation
        }
      }
    }
  }
}
`;