import { combineReducers } from 'redux';
import themeReducer from './theme/reducer';
import accountReducer from './account/reducers';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import notificationsReducer from './notifications/reducers';
import schedulingReducer from './scheduling/reducers';

const state = {
    theme: themeReducer, 
    account: accountReducer,
    notifications: notificationsReducer,
    scheduling: schedulingReducer,
};
const rootReducer = combineReducers(state);

export type AppState = ReturnType<typeof rootReducer>
const persistConfig: PersistConfig<AppState> = {
    key: 'root',
    storage,
    whitelist: ['theme', 'account', 'organization', 'scheduling'],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer);


export const mapStateToProps = (state: AppState) => ({
    theme: state.theme, 
    account: state.account,
    notifications: state.notifications,
    scheduling: state.scheduling,
});