import { DefaultTheme } from "styled-components";

  
  // extend the module declarations using custom theme type
   
  export const myTheme: DefaultTheme = {
    layout: {
      headerColor: '#222D2F',
      background: '#0B0C10',
      text:"#66FCF1"
    },
    colors: {
      primary: {
        self:'#66FCF1',
        text:'black'
      },
      secondary:{
        self: '#45A29E',
        text:'white'
      },
      tertiary: {
        self: '#1F2833',
        text:'white'
      },
      warning:{
        self: '#a88c32',
        text:'black'
      },
      error: {
        self: '#DE5900',
        text:'black'
      },
      info:{
        self: 'blue',
        text:'white'
      },
      success: {
        self: 'green',
        text:'white'
      },
    },
  };


  export const oceana: DefaultTheme = {
    layout: {
      headerColor: '#222D2F',
      background: '#EEE8A9',
      text:"#434655"
    },
    colors: {
      primary: {
        self:'#335EB4',
        text:'white'
      },
      secondary:{
        self: '#3f5e73',
        text:'white'
      },
      tertiary: {
        self: '#1F2833',
        text:'white'
      },
      warning:{
        self: '#a88c32',
        text:'black'
      },
      error: {
        self: '#DE5900',
        text:'black'
      },
      info: {
        self: 'blue',
        text:'white'
      },
      success: {
        self: 'green',
        text:'white'
      },
    },
  };

  export const grassy: DefaultTheme = {
    layout: {
      headerColor: '#4ECF3C',
      background: '#778D74',
      text:"white"
    },
    colors: {
      primary: {
        self:'#4ECF3C',
        text:'white'
      },
      secondary:{
        self: '#B247C1',
        text:'white'
      },
      tertiary: {
        self: '#1F2833',
        text:'white'
      },
      warning:{
        self: '#a88c32',
        text:'black'
      },
      error: {
        self: '#DE5900',
        text:'black'
      },
      info:{
        self: 'blue',
        text:'white'
      },
      success: {
        self: 'green',
        text:'white'
      },
    },
  };

  export const babyBlue: DefaultTheme = {
    layout: {
      headerColor: '#2080DF',
      background: '#EFEFEF',
      text:"#202020"
    },
    colors: {
      primary: {
        self:'#2080DF',
        text:'white'
      },
      secondary:{
        self: '#7EB6EF',
        text:'white'
      },
      tertiary: {
        self: '#1F2833',
        text:'white'
      },
      warning:{
        self: '#a88c32',
        text:'black'
      },
      error: {
        self: '#DE5900',
        text:'black'
      },
      info:{
        self: 'blue',
        text:'white'
      },
      success: {
        self: 'green',
        text:'white'
      },
    },
  };

  export const themes:{name: string, theme: DefaultTheme}[] = [
    {
      name: 'Oceana',
      theme: oceana,
    },
    {
      name: 'Night Life',
      theme: myTheme
    },
    {
      name: 'grassy',
      theme: grassy,
    },
    {
      name: 'Baby Blue',
      theme: babyBlue,
    }
  ]