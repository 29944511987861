import { AccountActionTypes } from "store/account/actions";
import { ColorOptions } from "styled-components";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const EXPIRE_NOTIFICATION = "EXPIRE_NOTIFICATION";
export const ACKNOWLEDGE_NOTIFICATION = "ACKNOWLEDGE_NOTIFICATION";

export enum AffectedResource {
  Account,
  Client,
  ConsumedSupply,
  MileageRecording,
  Note,
  Region,
  Reminder,
  Supply,
  Employee
}

export interface Notification {
  id: string;
  timestamp: Date;
  subject: string;
  details?: string;
  link?: string;
  level?: ColorOptions;
  resource?: AffectedResource;
}

export interface NotificationState {
  notifications: ReadonlyArray<Notification>;
  affectedResource?: AffectedResource;
}

export interface AddNotificationPayload {
  id?: string;
  subject: string;
  details?: string;
  link?: string;
  level?: ColorOptions;
  resource?: AffectedResource;
}

export interface CreateNotificationAction {
  type: typeof CREATE_NOTIFICATION;
  payload: AddNotificationPayload;
}

export interface ExpireNotificationAction {
  type: typeof EXPIRE_NOTIFICATION;
  payload: { notificationId: string };
}

export interface AcknowledgeNotificationAction {
  type: typeof ACKNOWLEDGE_NOTIFICATION;
  payload: { notificationId: string };
}

export function createNotification(payload: AddNotificationPayload) {
  return { type: CREATE_NOTIFICATION, payload };
}

export function expireNotification(payload: { notificationId: string }) {
  return { type: EXPIRE_NOTIFICATION, payload };
}

export function acknowledgeNotification(payload: { notificationId: string }) {
  return { type: ACKNOWLEDGE_NOTIFICATION, payload };
}

export type NotificationActionTypes =
  | AccountActionTypes
  | CreateNotificationAction
  | ExpireNotificationAction
  | AcknowledgeNotificationAction;
