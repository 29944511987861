import {
  SET_SCHEDULING_REGION,
  SchedulingState,
  SchedulingActionTypes
} from "./actions";

const initialState: SchedulingState = {
  selectedRegionId: 'all',
};

export default function schedulingReducer(
  state = initialState,
  action: SchedulingActionTypes
): SchedulingState {
  switch (action.type) {
    case SET_SCHEDULING_REGION: {
      const payload = action.payload;
      return {
        selectedRegionId: payload.regionId,
      };
    }
    default: {
      return state;
    }
  }
}
