import React, { useEffect } from 'react';
import { SelectOptions, SelectOption } from './shared';
import Select from 'react-select';
import { SelectFormGrouping, SelectLabel } from 'components/form/input';

export default function Autocomplete(props: {
    loading?: boolean,
    disabled?: boolean,
    label: string,
    options: SelectOptions,
    onSearchUpdate?: (val: string) => void,
    name: string,
    onSelect: (value: string) => void,
    value?: string,
}) {

    const { options, onSearchUpdate, onSelect, value, disabled, loading, name, label } = props;
    const [input, setInput] = React.useState('');

    const handleSearchUpdate = (val: string) => {
        if (onSearchUpdate) {
            onSearchUpdate(val);
        }
        setInput(val);
    };

    useEffect(() => {
        if(!value) {
            setInput((val) => '');
        }
    }, [value]);

    const defaultValue = options.find(x => x.value === value);

    return (
        <SelectFormGrouping>
            <SelectLabel htmlFor={name}>{label}</SelectLabel>
            <Select
                key={value}
                menuPortalTarget={document.body}
                styles={{
                    // Fixes the overlapping problem of the component
                    container: provided => ({ ...provided, zIndex: 0 }),
                    menuPortal: provided => ({ ...provided, zIndex: 1000 }),
                    input: provided => ({
                        ...provided, visibility: 'visible', opacity: 1,
                    }),
                    singleValue: provided => ({
                        ...provided, visibility: 'visible', opacity: 1, color: 'inherit'
                    }),
                }}
                options={options}
                isLoading={loading}
                onChange={(changedValue) => {
                    onSelect((changedValue as SelectOption)?.value || '')
                }}
                value={defaultValue}
                isSearchable={true}
                filterOption={(val, search) => onSearchUpdate ? true : val.label.toLowerCase().includes(search.toLowerCase())}
                onInputChange={handleSearchUpdate}
                isDisabled={disabled}
                inputValue={input}
                name={name} 
                inputId={name}
                isClearable={!disabled}
            />
        </SelectFormGrouping>
    )
}
