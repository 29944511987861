import React from 'react';
import { FormProps } from 'forms/form-props';
import * as Yup from 'yup';
import { SelectOptions } from 'components/autocomplete/shared';
import { Formik, Form, FieldArray } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Autocomplete from 'components/autocomplete/autocomplete';
import { FormGrouping, FormLabel, Input } from 'components/form/input';
import Button from 'components/button';
import { Trash } from 'styled-icons/boxicons-solid';
import { Plus } from 'styled-icons/boxicons-regular';

interface ConsumedSupply {
    supplyId: string;
    quantity: number;
}

export interface ConsumeSuppliesRequest {
    supplies: ConsumedSupply[];
    clientId: string;
    purchaseDate: Date,
}

export function ConsumeSuppliesForm(props: FormProps<ConsumeSuppliesRequest> & {
    onClientAutocompleteChange: (search: string) => void,
    clientOptions: SelectOptions,
    loadingClientOptions: boolean,
    supplyOptions: SelectOptions,
}) {
    const { onSubmit, loading, disabled } = props;
    const schema = Yup.object<ConsumeSuppliesRequest>().shape({
        supplies: Yup.array<ConsumedSupply>().of(Yup.object().shape({
            quantity: Yup.number().default(0).min(1).required('Quantity cannot be blank'),
            supplyId: Yup.string().required('Please select a supply'),
        }).default({}).required()).default([]).required(),
        clientId: Yup.string().default('').required('Client is required'),
        purchaseDate: Yup.date().default(new Date()).max(new Date(), 'A future date cannot be selected').required('Purchase date is required'),
    }).required();


    return (
        <Formik
            initialValues={schema.default()}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit({
                    ...values
                });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
                setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.purchaseDate && errors.purchaseDate}>
                                    <Input
                                        name={'purchaseDate'}
                                        type={'date'}
                                        value={values.purchaseDate?.toISOString?.().split('T')[0]}
                                        disabled={disabled}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormLabel>Purchase Date</FormLabel>
                                </FormGrouping>
                            </Col>
                            <Col xs={12}>
                                <FormGrouping error={touched.clientId && errors.clientId}>
                                    <Autocomplete
                                        name={'clientId'}
                                        label={'Client'}
                                        onSelect={(val) => setFieldValue('clientId', val)}
                                        value={values.clientId}
                                        options={props.clientOptions}
                                        disabled={disabled}
                                        loading={props.loadingClientOptions}
                                        onSearchUpdate={props.onClientAutocompleteChange}
                                    />
                                </FormGrouping>
                            </Col>
                        </Row>

                        <FieldArray name={"supplies"}
                            render={(arrayHelpers) =>
                            (<>
                                {values.supplies?.map((supply, i) => (

                                    supply && <Row key={i} middle={'xs'}>
                                        <Col xs={6}>
                                            <FormGrouping error={touched.supplies?.[i]?.supplyId && (errors.supplies?.[i] as any)?.supplyId}>
                                                <Autocomplete
                                                    name={`supplies[${i}].supplyId`}
                                                    label={'Supply'}
                                                    onSelect={(val) => setFieldValue(`supplies[${i}].supplyId`, val)}
                                                    value={supply.supplyId}
                                                    options={props.supplyOptions}
                                                    disabled={disabled}
                                                    loading={props.loadingClientOptions}
                                                />
                                            </FormGrouping>
                                        </Col>
                                        <Col xs={4}>
                                            <FormGrouping error={touched.supplies?.[i]?.quantity && (errors.supplies?.[i] as any)?.quantity}>
                                                <Input name={`supplies[${i}].quantity`} placeholder={" "} type={"number"} value={supply.quantity} onChange={handleChange} onBlur={handleBlur} />
                                                <FormLabel>Quantity</FormLabel>
                                            </FormGrouping>
                                        </Col>
                                        <Col xs={2}>
                                            <Button tight title={'Remove Row'} mode={'warning'} onClick={arrayHelpers.handleRemove(i)} ><Trash size={30} /></Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button type={'button'} tight mode={'primary'} title={'Add Consumed Supply'} onClick={arrayHelpers.handlePush({ quantity: 1, supplyId: '' })}><Plus size={20} /></Button>
                            </>)}

                        />

                        {
                            props.serverError && <Row>
                                <Col xs={12}>{props.serverError}</Col>
                            </Row>
                        }
                        < Row >
                            <Col>
                                <Button type={"submit"} mode={"primary"} disabled={loading}>Save Consumed Supply</Button>
                            </Col>
                        </Row>
                    </Grid>
                </Form>
            )
            }
        </Formik >
    );
}