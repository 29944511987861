import styled from 'styled-components';
import { Link } from '@reach/router';

export const ButtonLink = styled(Link)`
 
     text-decoration: none;
     display: flex;
     gap: 4px;
     align-items: center;
`;
