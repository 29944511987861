import styled, { ColorOptions } from 'styled-components';
const Button = styled.button<{ mode: ColorOptions, stroked?: boolean, tight?: boolean, disabled?: boolean, noMargin?: boolean, fullWidth?: true }>`
    border: ${props => props.stroked ? '2px solid ' + props.theme.colors[props.mode].self : 'none'};
    background-color: ${props => props.stroked ? props.theme.colors[props.mode].text : props.theme.colors[props.mode].self};
    color: ${props => props.stroked ? props.theme.colors[props.mode].self : props.theme.colors[props.mode].text};
    padding: ${props => props.tight ? '5px 10px' : '10px 15px'};
    font-size: ${props => props.tight ? '14px' : '16px'};
    font-weight: bold;
    margin: ${props => props.noMargin ? 0 : 5}px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    border-radius: 3px;
    ${props => props.disabled && `opacity: 0.6;`};
    transition-duration: 200ms;
    display: inline-flex;
    align-items: center;

    ${props => props.fullWidth && 'width: 100%;'}

    &:active {
        transform: scale(.99);
        border:none;
    }
    &:focus {
        outline: 1px solid ${props => props.stroked ? props.theme.colors[props.mode].self : props.theme.colors[props.mode].text};
    }

    &:hover:not(:active) {
        /* transform: scale(1.01); */
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
    } 

`;

export const IconButton = styled.button<{ disabled?: boolean }>`
    border: none;     
    
    padding: 0;
    border: none;
    background-color: inherit;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}; 
    ${props => props.disabled && `opacity: 0.6;`};
    transition-duration: 200ms;
    &:active {
        transform: scale(.99);
        border:none;
    }
    &:focus {
        outline: 1px solid ${props => props.theme.colors.primary.self};
    }
    align-items: center;

    
    &:hover:not(:active) {
        transform: scale(1.01);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

`;

export default Button;