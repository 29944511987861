import { AccountState, AccountActionTypes, LOGIN, LOG_OUT, UPDATE_PERSONAL_INFO } from "./actions";
import { clearToken, setToken } from "config/apollo";
 


const initialState: AccountState = {
    accountId: '',
    firstname: '',
    lastname: '',
    email: '',
    permissions: []
};

export default function accountReducer(state = initialState, action: AccountActionTypes): AccountState {
    switch (action.type) {
        case LOGIN: {
            const payload = action.payload;
            setToken(payload.jwt);
            return {
                accountId: payload.accountId,
                firstname: payload.firstname,
                lastname: payload.lastname,
                email: payload.email,
                permissions: payload.permissions,
            };
        }
        case UPDATE_PERSONAL_INFO: {
            const payload = action.payload;
            return {
                accountId: payload.accountId,
                firstname: payload.firstname,
                lastname: payload.lastname,
                email: payload.email,
                permissions: payload.permissions,
            }
        }
        case LOG_OUT: {
            clearToken();
            return { ...initialState };
        } 
        default: {
            return state;
        }
    }
}