import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.layout.text};
    background-color: ${props => props.theme.layout.background};
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.05em;
  }
  @media only screen and (min-width: 1600px)  {
    .container  {
      width: 1584px;
    }
  }

  
  @media only screen and (min-width: 2500px)  {
    .container  {
      width: 2484px;
    }
  }
  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  a { 
    color: inherit !important;
  }
  a:active {
    text-decoration: none !important;
  }
  
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;    
}
`;