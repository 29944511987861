import React from 'react';
import { FormProps } from 'forms/form-props';
import * as Yup from 'yup';
import { SelectOptions } from 'components/autocomplete/shared';
import { Formik, Form } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Autocomplete from 'components/autocomplete/autocomplete';
import { FormGrouping, FormLabel, Input } from 'components/form/input';
import Button from 'components/button';
import { format } from 'date-fns';


export interface AddReminderRequest {
    reminder: string;
    dueDate?: string;
    clientId?: string;
}

export function AddReminderForm(props: FormProps<AddReminderRequest> & {
    onClientAutocompleteChange: (search: string) => void,
    clientOptions: SelectOptions,
    loadingClientOptions: boolean,
}) {
    const { onSubmit, loading, disabled } = props;
    const schema = Yup.object<AddReminderRequest>().shape({
        reminder: Yup.string().default('').required('Reminder is required'),
        clientId: Yup.string().default('').required('Client is required'),
        dueDate: Yup.string().default(format(new Date(), 'yyyy-MM-dd')),
    }).required();


    return (
        <Formik
            initialValues={schema.default()}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit({
                    ...values
                });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.clientId && errors.clientId}>
                                <Autocomplete
                                    name={'clientId'}
                                    label={'Client'}
                                    onSelect={(val) => setFieldValue('clientId', val)}
                                    value={values.clientId}
                                    options={props.clientOptions}
                                    disabled={disabled}
                                    loading={props.loadingClientOptions}
                                    onSearchUpdate={props.onClientAutocompleteChange}
                                />
                                </FormGrouping>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.reminder && errors.reminder}>
                                    <Input name={"reminder"} placeholder={" "} type={"text"} value={values.reminder} onChange={handleChange} onBlur={handleBlur} />
                                    <FormLabel>Reminder</FormLabel>
                                </FormGrouping>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGrouping error={touched.dueDate && errors.dueDate}>
                                    <Input name={"dueDate"} placeholder={" "} type={"date"} value={values.dueDate?.toString()} onChange={handleChange} onBlur={handleBlur} />
                                    <FormLabel>Due Date</FormLabel>
                                </FormGrouping>
                            </Col>
                        </Row>
                        {props.serverError && <Row>
                            <Col xs={12}>{props.serverError}</Col>
                        </Row>}
                        <Row>
                            <Col>
                                <Button type={"submit"} mode={"primary"} disabled={loading}>Save Reminder</Button>
                            </Col>
                        </Row>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}