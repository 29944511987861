import { DefaultTheme } from "styled-components";

export const CHANGE_THEME = 'CHANGE_THEME';
export const RESET_THEME = 'RESET_THEME';

export interface ThemeState {
    theme: DefaultTheme,
    name: string,
    index: number,
}

interface ChangeThemeAction {
    type: typeof CHANGE_THEME, 
}

interface ResetThemeAction {
    type: typeof RESET_THEME    
}

export function changeTheme() {
    return {type: CHANGE_THEME};
}

export function resetTheme() {
    return {type: RESET_THEME};
}
 
export type ThemeActionTypes = ResetThemeAction | ChangeThemeAction;