import React, { useState } from 'react';
import styled from 'styled-components';
import Clickable from '../utils/clickable';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Menu } from 'styled-icons/boxicons-regular';
import { Link, navigate } from '@reach/router';
import Button from '../button';
import { UserCircle } from 'styled-icons/boxicons-solid';
import Dropdown from '../navigation/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { logoutUser } from 'store/account/actions';
import { AccountSelectors } from 'store/account/selectors';
import { ButtonLink } from 'components/button-link';

const HeaderWrapper = styled.div`
    background-color: ${props => props.theme.colors.primary.self};
    color:  ${props => props.theme.colors.primary.text};
    padding-right: 8px;
`;

const Title = Clickable(styled(Link)`
    margin-left:10px;
    padding-bottom:5px;
    font-size: 30px;
    font-weight: bold;`
);

export default function Header(props: {
    title: string,
    titleClicked: () => void,
    menuClicked?: () => void,
    menuPosition?: 'left' | 'right' | undefined,
}) {

    const loggedIn = useSelector(AccountSelectors.isLoggedIn);
    let { title, titleClicked, menuClicked, menuPosition } = props;


    if (!menuPosition) {
        menuPosition = 'left';
    }
    return (
        <HeaderWrapper>
            <Grid fluid>
                <Row between={menuPosition === 'right' ? "xs" : undefined} middle={"xs"}>
                    {menuPosition === 'left' && menuClicked && <Col>
                        <MenuButton menuClicked={menuClicked} />
                    </Col>}
                    <Col>
                        <Title to={"./"} onClick={() => { titleClicked(); }}> {title} </Title>
                    </Col>
                    {menuPosition === 'right' && menuClicked &&
                        <Col>
                            <Row middle='xs' around={'xs'}>
                                {loggedIn === false && <Button mode={"secondary"} onClick={() => navigate('/account/login')}>Log in</Button>}
                                {loggedIn && <UserDropdownSection />}
                                <Col>
                                    <MenuButton menuClicked={menuClicked} />
                                </Col>
                            </Row>
                        </Col>}
                </Row>
            </Grid>
        </HeaderWrapper >
    )
}

const UserDropdownSection = (props: {}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const closeDropdown = () => setDropdownOpen(false);
    const openDropdown = () => setDropdownOpen(true);

    return (
        <Dropdown
            showDropdown={dropdownOpen}
            handleBlur={closeDropdown}
            showOnHover={false}
            wrapped={
                <Button mode={"secondary"} onClick={openDropdown} >
                    <UserCircle title={"Profile"} size={25} />
                </Button>
            }
        >
            <LoggedInOptions />
        </Dropdown>);
}

const LoggedInOptions = (props: {}) => {

    const dispatch = useDispatch();
    const apolloClient = useApolloClient();
    const accountId = useSelector(AccountSelectors.accountId);

    const logout = () => {
        dispatch(logoutUser());
        apolloClient.resetStore();
        navigate('/account/login');
    }
    return (
        <Grid fluid>
            <Row>
                <Col xs={12}>
                    <ButtonLink to={`/accounts/edit-account/${accountId}`}><Button mode={'primary'}>View Account</Button></ButtonLink>
                </Col>
                <Col xs={12}>
                    <ButtonLink to={`/accounts/activity/${accountId}`} ><Button mode={'secondary'}>View Activity</Button></ButtonLink>
                </Col>
                <Col xs={12}>
                    <Button mode={'secondary'} tabIndex={0} onClick={logout}>Log out</Button>
                </Col>

            </Row>
        </Grid>
    )
}

const ClickableMenu = Clickable(Menu as any);

const MenuButton = (props: {
    menuClicked: () => void,
}) => {
    const { menuClicked } = props;
    return (
        <ClickableMenu size={30} title={"Menu"} onClick={() => menuClicked && menuClicked()} />
    );
}