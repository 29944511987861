import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Notification } from 'store/notifications/actions';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Delete } from 'styled-icons/material';
import { Link } from '@reach/router';

const ToastWrapper = styled.div`
    position: fixed;
    top: 50px;
    right: 0px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
`;

export default function Toast(props: {
    timeout: number,
    messages: readonly Notification[],
    onMessageTimeout: (notificationId: string) => void,
    onMessageAcknowledge: (notificationId: string) => void,
}) {
    const { timeout, messages, onMessageTimeout, onMessageAcknowledge } = props;

    const map = new Map<string, Notification>();

    messages.forEach(x => map.set(x.id, x));
    const unique = Array.from(map.values());
    return (
        <ToastWrapper>
            {unique.map(message =>
                <ToastItem
                    key={message.id}
                    timeout={timeout}
                    message={message}
                    onMessageTimeout={onMessageTimeout}
                    onMessageAcknowledged={onMessageAcknowledge}
                />
            )}

        </ToastWrapper>
    )
}

const ToastItemWrapper = styled.div<{ timeout: number, closed: boolean }>`
    background-color: ${props => props.theme.colors.secondary.self};
    color: ${props => props.theme.colors.secondary.text};
    min-width: 20vw; 
    align-self:flex-end;
    max-width: 80%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: static ;
    border-top-color: ${props => props.theme.colors.secondary.text};
    border-bottom-color: ${props => props.theme.colors.secondary.text};
    border-left-color: ${props => props.theme.colors.secondary.text}; 
    border: 1px solid;
    border-right-width: 0px; 
    &:focus {
        outline:0;
    } 
    margin: 5px 0px 5px 5px; 
    animation-name: ${props => props.closed ? 'fade' : 'slideIn'};
    animation-duration: ${props => props.closed ? 750 : (props.timeout / .7)}ms;     
    animation-delay: 0ms;
    animation-fill-mode: forwards;       
    @keyframes slideIn {
        0%   {  transform: translateX(200%); }
        15%  {  transform: translateX(0%);   } 
        85%  {  transform: translateX(0%);   } 
        100% {  transform: translateX(200%); }
    }   

    @keyframes fade {
        0%   { 
            opacity: 1;        
            background-color: ${props => props.theme.colors.secondary.self};
            color: ${props => props.theme.colors.secondary.text};
         } 
        100% {
            opacity: 0;        
            background-color: ${props => props.theme.colors.secondary.text};
            color: ${props => props.theme.colors.secondary.self};
         }
    }
`;

/**
 * 
 * @param {Notification} message the message to display
 * @param {number} timeout the timeout in milliseconds of the notification
 * @param {function} clearMessage called when the notificiation is finished displaying. Contains the id of the notification
 **/
const ToastItem = (props: { message: Notification, timeout: number, onMessageTimeout: (id: string) => void, onMessageAcknowledged: (id: string) => void }) => {


    const { timeout, onMessageTimeout, message } = props;
    const { id } = message;
    const [toastClosed, setToastCLosed] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (!timer) {
            let a = setTimeout(() => {
                onMessageTimeout(id)
            }, (timeout | 0) / .7);
            setTimer(a);
            return () => {
                if (timer) {
                    clearTimeout(timer as unknown as NodeJS.Timeout);
                }
            }
        }
    }, [timer, id, onMessageTimeout, timeout]);

    const closeToast = () => {
        if (!toastClosed) {
            if (timer) {
                clearTimeout(timer as unknown as NodeJS.Timeout);
            }
            setToastCLosed(true);
            let a = setTimeout(() => {
                onMessageTimeout(id)
            }, 750);
            setTimer(a);
        }
    }

    return (
        <ToastItemWrapper closed={toastClosed} timeout={props.timeout}>
            <Grid fluid>
                <Row between={"xs"}>
                    {props.message.link ?
                        <Link to={props.message.link}>
                            <Col xs={9}>
                                <h4>{props.message.subject}</h4>
                            </Col>
                        </Link>
                        :
                        <Col xs={9}>
                            <h4>{props.message.subject}</h4>
                        </Col>}
                    <Col xs={3}>
                        <Row end={"xs"}>
                            <Col>
                                <Delete size={30} onClick={() => closeToast()} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {props.message.details &&
                    <Row>
                        <Col xs={12}>
                            {props.message.details}
                        </Col>
                    </Row>
                }
            </Grid>
        </ToastItemWrapper>
    );
}