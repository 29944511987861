import React from 'react'; 
import {SpeedDial} from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
  
export function QuickLinks(props:{
  actions: {icon: JSX.Element, name: string, action:()=> void}[],
  hidden: boolean,
}) {
  
    const [status, setStatus] = React.useState({
      direction: "up" as "up",
      open: false, 
    });
  
    const handleClick = () => {
      setStatus({ ...status, open: !status.open });
    };
  
    const handleClose = () => {
      setStatus({ ...status, open: false });
    };
    
    return (
      
          
      <SpeedDial
      className={'hidden-print'}
      color={"secondary"} 
      style={{right:'3%', bottom:'2%', position: 'fixed'}}
      ariaLabel="SpeedDial example" 
      icon={<SpeedDialIcon />}
      openIcon={<SpeedDialAction />}
      // onBlur={handleClose}
      onClick={handleClick}
      hidden={props.hidden}
      onClose={handleClose}
      onMouseLeave={handleClose}
      open={status.open}
      
      direction={status.direction}
    >
      {props.actions.map(action => (
        <SpeedDialAction style={{marginBottom:'5px'}}
          key={action.name}
          icon={action.icon}
          title={action.name}          
          onClick={action.action}
        />
      ))}
    </SpeedDial>
    )
  }