import gql  from 'graphql-tag';

export const FIND_COMPANY_AUTOCOMPLETE = gql`
query findCompanyAutocomplete($companyName: String!) {
    findCompanies(input: {
      companyName: $companyName
    }) {
      id
      companyName
    }
  }
`;