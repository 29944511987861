import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Close } from 'styled-icons/remix-fill';
import WithColor from '../utils/colored-icon';
import ThemePicker from '../theme-picker';
import Clickable from '../utils/clickable';
import { Link } from '@reach/router';
const Slider = styled.div<{ isOpen: boolean, navPosition: 'left' | 'right' }>`
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    ${props => props.navPosition === 'left' && `
    left: ${props.isOpen ? '0px' : '-250px'}`};
    ${props => props.navPosition === 'right' && `
    right: ${props.isOpen ? '0px' : '-250px'}`};
    background-color: ${props => props.theme.layout.background};
    overflow-x: hidden;
    padding-top: 5px;
    padding-left:5px;
    color: ${props => props.theme.layout.text};
    border-right: 1px solid ${props => props.theme.layout.text};
    transition: 0.3s;
    &:focus {
        outline: none;
    }
    border-left: 2px solid ${props => props.theme.colors.tertiary.self};
`;


const CloseButton = Clickable(WithColor(Close as any,{color: "primary"}));

export default function Navbar(props: {
    children?: React.ReactNode,
    isOpen: boolean,
    navPosition: 'left' |'right',
    handleClosure: () => void,
}) {
    const { children, isOpen, handleClosure, navPosition } = props;
    

    const [dropdownOpen, setDropDownOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    React.useEffect(()=> {
        if(props.isOpen && !dropdownOpen && ref.current) {
            ref.current.focus();
        }
        if(props.isOpen !== dropdownOpen) {
            setDropDownOpen(props.isOpen || false);
        }
    }, [props.isOpen, dropdownOpen]);

    return (
        <Slider ref={ref} tabIndex={0} onMouseLeave={() => handleClosure()} navPosition={navPosition} isOpen={isOpen}>
            <Grid fluid>
                <Row between={"xs"}>
                    <Col style={{marginBottom: '10px'}}><ThemePicker /></Col>
                    <Col><CloseButton title={"Close Menu"} size={30} onClick={() => handleClosure()} /></Col>
                </Row>
            </Grid>
            {children}
        </Slider>
    )
}

const NavOptionWrapper = styled.div`
&:hover {
    color: ${props=> props.theme.colors.primary.self};
}
font-size:18px; 
margin-top: 5px;
margin-left: 5px;
`;

export const NavOption = (props:{
    title: string,
    link: string
}) => {
    const {title, link} = props;
    return (
        <Link to={link}>
        <NavOptionWrapper>{title}</NavOptionWrapper>
        </Link> 
    )
}