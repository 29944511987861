import gql from 'graphql-tag';

export const ADD_SUPERVISOR_REMINDER_MUTATION = gql`
mutation AddSupervisorReminder($input:AddSupervisorReminderInput!) {
  addSupervisorReminder(request: $input) {
    id
    type
    createdOn
    reminder
    dueDate
    completedTimestamp
    userEvent {
      id
      createdOn      
    }
  }
}
`;