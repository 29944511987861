import { Permission } from "graphql/globalTypes";

export const LOGIN = 'LOGIN';
export const LOG_OUT = 'LOG_OUT';
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export interface AccountState {
    firstname: string;
    lastname: string;
    accountId: string;
    email: string;
    permissions: readonly Permission[];
}

export interface LoginPayload {
    accountId: string;
    email: string;
    firstname: string;
    lastname: string; 
    permissions: readonly Permission[];
    jwt: string;
}

export interface UpdatePersonalInfoPayload {
    accountId: string;
    email: string;
    firstname: string;
    lastname: string; 
    permissions: readonly Permission[];
}
export interface LoginAction {
    type: typeof LOGIN;
    payload: LoginPayload;
}

export interface UpdatePersonalInfoAction {
    type: typeof UPDATE_PERSONAL_INFO;
    payload: UpdatePersonalInfoPayload;
}

interface LogoutAction {
    type: typeof LOG_OUT;
    payload:{}
}
 
export function login(payload: LoginPayload ) {
    return {type:LOGIN, payload };
}

export function updatePersonalInfo(payload: UpdatePersonalInfoPayload ) {
    return {type:UPDATE_PERSONAL_INFO, payload };
}

export function logoutUser(): LogoutAction {
    return {type: LOG_OUT, payload:{}};
} 

export type AccountActionTypes = LoginAction | LogoutAction | UpdatePersonalInfoAction;
