import React, { lazy, StrictMode, Suspense } from 'react';
import './App.scss';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './global-styles';
import { persistStore } from 'redux-persist';
import { Provider as ReduxProvider, useSelector, useDispatch } from 'react-redux';
import { createStore } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistedReducer, AppState } from 'store';
import { getEnvironmentConfig } from 'config/environment';
import { getApolloClient } from 'config/apollo';
import { ApolloProvider } from '@apollo/client';
import MainPage, { Loading } from 'pages';
import { Router } from '@reach/router'; 
import { acknowledgeNotification, expireNotification } from 'store/notifications/actions';
import { NotificationSelectors } from 'store/notifications/selectors';
import Toast from 'components/toast';
import { ModalProvider } from 'styled-react-modal';




const envConfig = getEnvironmentConfig();
const store = createStore(persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());
export type storeType = typeof store;

function App() {
  const client = getApolloClient(envConfig, store);
  const persistor = persistStore(store);
  return ( 
    <StrictMode>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ModalProvider>
              <More />
            </ModalProvider>
          </PersistGate>
        </ReduxProvider>
      </ApolloProvider> 
    </StrictMode>
  );
}

const LazyAccountPage = lazy(() => import('pages/account'));

export function More() {
  const theme = useSelector((state: AppState) => state.theme);
  const notifications = useSelector(NotificationSelectors.notifications);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme.theme}>
      <GlobalStyle />
      <Toast
        messages={notifications}
        onMessageAcknowledge={(id) => dispatch(acknowledgeNotification({ notificationId: id }))}
        onMessageTimeout={(id) => dispatch(expireNotification({ notificationId: id }))}
        timeout={5000}
      />

    <Suspense fallback={<Loading />}>
      <Router>
        {/** 
         * Putting account page here, as the main page needs to redirect to it if not logged in. If we put the account in main page, the app gets stuck in a loop redirecting
         */}
        <LazyAccountPage path={process.env.PUBLIC_URL + '/account/*'} />
        <MainPage path={process.env.PUBLIC_URL + '/*'} />
      </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
