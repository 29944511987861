import React from 'react';
import { Paint } from 'styled-icons/remix-fill';
import WithColor from './utils/colored-icon';
import Clickable from './utils/clickable';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from 'store/theme/actions';
import styled from 'styled-components'; 
import { AppState } from 'store';


const ColoredPaint = Clickable(WithColor(styled(Paint)``, {color:  "primary"}));

export default function ThemePicker(props: {
}) {

    const dispatch = useDispatch();
    const theme = useSelector((state: AppState) => state.theme.name);
    const handleThemeChange = () => {
        dispatch(changeTheme());
    };

    return (
        <ColoredPaint title={`Transition Theme (${theme})`} size={30} onClick={handleThemeChange} />
    );

}