/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The type of money being used
 */
export enum Currency {
  USD = "USD",
}

export enum Permission {
  ADD_JOB_RUN = "ADD_JOB_RUN",
  EDIT_COMPANY = "EDIT_COMPANY",
  EDIT_REGION = "EDIT_REGION",
  EDIT_SUPPLIES = "EDIT_SUPPLIES",
  MANAGE_ACCOUNTS = "MANAGE_ACCOUNTS",
  REVIEW_PLANNER = "REVIEW_PLANNER",
  SCHEDULING = "SCHEDULING",
  SUPERVISOR_PLANNER = "SUPERVISOR_PLANNER",
  VIEW_COMPANY = "VIEW_COMPANY",
}

/**
 * Determines what the specific type of event this is for
 */
export enum UserEventType {
  ADD_COMPANY = "ADD_COMPANY",
  ADD_MILEAGE = "ADD_MILEAGE",
  ADD_NOTE = "ADD_NOTE",
  ADD_REGION = "ADD_REGION",
  ADD_REMINDER = "ADD_REMINDER",
  CONSUMED_SUPPLY = "CONSUMED_SUPPLY",
  DELETE_CLOCKIN = "DELETE_CLOCKIN",
  DELETE_JBA = "DELETE_JBA",
  EDIT_CLOCKIN = "EDIT_CLOCKIN",
  MANUAL_CLOCKIN = "MANUAL_CLOCKIN",
}

export interface AccountSearchInput {
  readonly skip?: number | null;
  readonly take?: number | null;
  readonly email?: string | null;
  readonly firstname?: string | null;
  readonly lastname?: string | null;
  readonly query?: string | null;
}

export interface AddConsumedSupplyInput {
  readonly consumedSupplies: ReadonlyArray<ConsumedSupplyInput>;
  readonly purchaseDate: any;
  readonly companyId: string;
}

export interface AddConsumedSupplyWithoutCompanyInput {
  readonly consumedSupplies: ReadonlyArray<ConsumedSupplyInput>;
  readonly purchaseDate: any;
}

export interface AddDailyPlannerInput {
  readonly companyId: string;
  readonly mileageRecording?: AddMileageRecordingWithoutCompanyInput | null;
  readonly supervisorNote: AddSupervisorNoteWithoutCompanyInput;
  readonly reminder?: AddSupervisorReminderWithoutCompanyInput | null;
  readonly consumedSupplies?: AddConsumedSupplyWithoutCompanyInput | null;
  readonly complete?: boolean | null;
}

export interface AddMileageRecordingInput {
  readonly odometerReading: number;
  readonly dateOccurred: string;
  readonly companyId: string;
}

export interface AddMileageRecordingWithoutCompanyInput {
  readonly odometerReading: number;
  readonly dateOccurred: string;
}

export interface AddPhoneToCompanyInput {
  readonly phoneNumber: string;
  readonly companyId: string;
}

export interface AddSupervisorNoteInput {
  readonly note: string;
  readonly requiresEscalation: boolean;
  readonly companyId: string;
}

export interface AddSupervisorNoteWithoutCompanyInput {
  readonly note: string;
  readonly requiresEscalation: boolean;
}

export interface AddSupervisorReminderInput {
  readonly reminder: string;
  readonly dueDate: any;
  readonly companyId: string;
}

export interface AddSupervisorReminderWithoutCompanyInput {
  readonly reminder: string;
  readonly dueDate: any;
}

export interface AssignInvalidClockinRequest {
  readonly invalidClockinId: string;
  readonly companyId: string;
}

export interface AuthenticateCredentialsInput {
  readonly email: string;
  readonly password: string;
}

export interface CompanySearchInput {
  readonly companyName: string;
  readonly regionId?: string | null;
}

export interface ConsumedSupplyInput {
  readonly quantity: number;
  readonly supplyId: string;
}

export interface CreateCompanyInput {
  readonly companyName: string;
  readonly regionId: string;
}

export interface CreateEmployeeInput {
  readonly firstname: string;
  readonly lastname: string;
  readonly employeeNumber?: string | null;
  readonly regionId: string;
  readonly externalId?: string | null;
}

export interface CreateJBAScheduleRequest {
  readonly jobBreakdownId: string;
  readonly sunday?: boolean | null;
  readonly monday?: boolean | null;
  readonly tuesday?: boolean | null;
  readonly wednesday?: boolean | null;
  readonly thursday?: boolean | null;
  readonly friday?: boolean | null;
  readonly saturday?: boolean | null;
  readonly startTime: string;
  readonly durationInHours: number;
  readonly effectiveDate: any;
  readonly expirationDate: any;
}

export interface CreateJobBreakdownInput {
  readonly companyId?: string | null;
  readonly generalNotes: string;
  readonly jobs?: ReadonlyArray<CreateJobGroupInput> | null;
  readonly title: string;
  readonly jobNumber: string;
}

export interface CreateJobGroupInput {
  readonly jobTitle: string;
  readonly jobNotes: string;
  readonly steps?: ReadonlyArray<CreateJobStepInput> | null;
  readonly orderNumber: number;
}

export interface CreateJobStepInput {
  readonly jobStepNotes: string;
}

export interface CreateManualClockinRequest {
  readonly clockIn: any;
  readonly clockOut: any;
  readonly employeeId: string;
  readonly companyId: string;
  readonly jobBreakdownId: string;
}

export interface DateRangeRequest {
  readonly start: any;
  readonly end: any;
}

export interface DeleteJBAScheduleRequest {
  readonly jbaScheduleId: string;
  readonly deleteForAll?: boolean | null;
  readonly deleteAfterDate?: any | null;
  readonly deleteForDate?: any | null;
}

export interface EditClockinRequest {
  readonly clockIn: any;
  readonly clockOut: any;
  readonly clockinId: string;
}

export interface EditCompanyInput {
  readonly id: string;
  readonly companyName: string;
  readonly regionId: string;
}

export interface EditJBAScheduleRequest {
  readonly jbaScheduleId: string;
  readonly editForAll?: boolean | null;
  readonly editAfterDate?: any | null;
  readonly editForDate?: any | null;
  readonly formData: CreateJBAScheduleRequest;
}

export interface EditableAccountValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly permissions: ReadonlyArray<Permission>;
}

export interface EditableAreaStepFields {
  readonly jobStepId?: string | null;
  readonly jobStepNotes: string;
  readonly orderNumber: number;
}

export interface EditableJobAreaFields {
  readonly jobAreaId?: string | null;
  readonly jobNotes: string;
  readonly title: string;
  readonly steps?: ReadonlyArray<EditableAreaStepFields> | null;
  readonly orderNumber: number;
}

export interface EditableJobBreakdownValues {
  readonly generalNotes: string;
  readonly areas?: ReadonlyArray<EditableJobAreaFields> | null;
  readonly title: string;
  readonly jobNumber: string;
}

export interface FindMileageRecordingInput {
  readonly userId: string;
  readonly startDate: any;
  readonly endDate: any;
}

export interface FindRangeByCompanyInput {
  readonly startDate: any;
  readonly endDate: any;
  readonly limit?: number | null;
}

export interface FindUserEventsInput {
  readonly userId?: string | null;
  readonly startDate: any;
  readonly take?: number | null;
  readonly types?: ReadonlyArray<UserEventType> | null;
}

export interface RegisterAccountInput {
  readonly registrationId: string;
  readonly newPassword: string;
}

export interface ResetPasswordInput {
  readonly passwordResetId: string;
  readonly newPassword: string;
}

export interface SeedAccountInput {
  readonly email: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly permissions: ReadonlyArray<Permission>;
}

export interface UpdateAccountInput {
  readonly accountId: string;
  readonly originalValues: EditableAccountValues;
  readonly newValues: EditableAccountValues;
}

export interface UpdateJobBreakdownInput {
  readonly jobBreakdownId: string;
  readonly originalValues: EditableJobBreakdownValues;
  readonly newValues: EditableJobBreakdownValues;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
