import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const DropdownContent = styled.div<{ visible: boolean }>`
    right:0px;
  //  ${props => props.visible ? 'display: block;' : 'display: none;'}
    max-height: ${props => props.visible ? 'auto' : '0px'};
    opacity: ${props => props.visible ? '1' : '0'};
    background: ${props => props.theme.layout.headerColor};  
    position: absolute; 
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
    transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -ms-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    &:focus {
        outline: none;
    }
  border-radius: 0px 0px 18px 18px;
`;

const DropdownBox = styled.div<{ showOnHover: boolean }>`
  position: relative;
  display: inline-block; 
  border-radius: 3px;
  ${props => props.showOnHover && `&:hover ${DropdownContent} {display: block;}`}; 
`;

export default function Dropdown(props: {
    wrapped: React.ReactNode,
    children: React.ReactNode,
    showOnHover: boolean,
    showDropdown?: boolean,
    handleBlur?: () => void,
}) {
    const { wrapped, children, showOnHover, showDropdown, handleBlur } = props;

    const ref = useRef<HTMLDivElement>(null);

    const [dropdownOpen, setDropDownOpen] = useState(false);
    const [mouseEntered, setMouseEntered] = useState(false);
    const mouseHasEntered = () => setMouseEntered(true);
    const mouseHasLeft = () => {
        if (mouseEntered) {
            handleBlur && handleBlur();
        }
        setMouseEntered(false);
    }


    React.useEffect(() => {
        if (props.showDropdown && !dropdownOpen && ref.current) {
            ref.current.focus();
        }
        if (props.showDropdown !== dropdownOpen) {
            setDropDownOpen(props.showDropdown || false);
        }
    }, [props.showDropdown, dropdownOpen]);

    return (
        <DropdownBox showOnHover={showOnHover}>
            {wrapped}
            <DropdownContent ref={ref} onMouseEnter={mouseHasEntered} onMouseLeave={mouseHasLeft} tabIndex={0} visible={!!showDropdown}>{showDropdown && children}</DropdownContent>
        </DropdownBox>
    )
}